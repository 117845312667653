import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UploaderCard from './UploaderCard/UploaderCard';

export default function Home() {
  const { loginWithRedirect,
          isAuthenticated,
          user,
          isLoading } = useAuth0();

  if(!isAuthenticated && isLoading){
    return <div>Loading...</div>
  }

  if(!isAuthenticated) {
    return loginWithRedirect();
  }
      
  return (
    <div>
      {isAuthenticated && (
        <div>
          <UploaderCard
            name={ user.name }
            />
        </div>
      )}
    </div>
  );
};

