import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './UploaderCard.module.css'
import UploaderButton from '../UploaderButton/UploaderButton'

export default function UploaderCard(props) {

  const { logout, user } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });


  if(user.extrainfo.metadata === undefined){
    window.location = '/unauthorized.html';
  }

  return (
    <div className={ styles.infoCard }>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="140"
          image="/images/logo.jpeg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Walmart Uploader
          </Typography>
          <Typography variant="body">
            { props.name }
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Selecciona y sube el archivo de homologación.
          </Typography>
          <br />
          <UploaderButton
            awsKey={ user.extrainfo.metadata.aws_key }
            awsSecret={ user.extrainfo.metadata.aws_secret }
          />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => logoutWithRedirect()}>Cerrar Sesión</Button>
        </CardActions>
      </Card>
    </div>
  );
}
