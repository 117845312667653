import React, { useRef, useState } from 'react';
import styles from './UploaderButton.module.css';
import { useAuth0 } from "@auth0/auth0-react";
import S3 from 'react-aws-s3';
import { Buffer } from 'buffer';
import CircularProgress from '@mui/material/CircularProgress';

class UploaderButton extends React.Component {


  constructor(props) {
    super(props);
    window.Buffer = Buffer;
    this.state = {
      uplStatus: 'pending',
      fileInput: null,
      uplText: null }
    this.fileUpload = this.fileUpload.bind(this);
    this.fileInput = React.createRef();
  }

  fileUpload = event => {
    this.setState({uplStatus: 'uploading'});
    let file = this.fileInput.current.files[0];
    let newFileName = this.fileInput.current.files[0].name;
    const config = {
      bucketName: 'walmart-uploader-files',
      dirName: 'pending',
      region: 'us-west-2',
      accessKeyId: this.props.awsKey, 
      secretAccessKey: this.props.awsSecret
    }
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then(data => {
      if(data.status === 204){
        this.setState({ uplText: 'Archivo Subido Correctamente' });
      } else {
        this.setState({ uplText: 'Error' });
      }
      this.setState({ uplStatus: 'done' });
    });
  }


  render() {
    const filetypes = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    if(this.state.uplStatus === 'pending'){
      return(
        <label className={ styles.uploadButton }>
          <input type="file" accept={ filetypes } ref={ this.fileInput } onChange={ () => this.fileUpload() } hidden/>
            Subir Archivo
        </label>
      );
    } else if(this.state.uplStatus === 'uploading'){
      return <CircularProgress />;
    } else {
      return <div className={ styles.doneButton }> { this.state.uplText } </div>;
    }

  }
}

export default UploaderButton;
